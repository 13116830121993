<template>
  <div class="table-responsive table-result">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="min-w-150px headerTable">KRAs</th>
        <th scope="col" class="min-w-120px headerTable">Yêu cầu thành phẩm</th>
        <th scope="col" class="min-w-130px headerTable">Bảng thành phẩm</th>
        <th scope="col" class="min-w-80px headerTable">Trọng số</th>
        <th scope="col" class="min-w-60px headerTable" v-for="(item, index) in listManagementKpi[0]?.kra_manager_weeks">KQ tuần {{index + 1}}</th>
        <th scope="col" class="min-w-80px headerTable">Kết quả KRA</th>
        <th scope="col" class="min-w-120px headerTable">Nhận xét</th>
      </tr>
      </thead>
      <tbody v-if="listManagementKpi?.length > 0">
        <tr v-for="(item, index) of listManagementKpi" :key="index + 'kra'" style="border: 1px solid #EBEDF3; border-top: 0">
          <td style="text-align: start;"><span :title="item?.name" class="line-clamp-1">{{item?.name}}</span></td>
          <td style="text-align: start"><span :title="item?.request_result" class="line-clamp-1" v-html="item?.request_result"></span></td>
          <td style="text-align: start"><span :title="item?.results" class="line-clamp-1">{{item?.results ?? 'Chưa có'}}</span></td>
          <td style="text-align: start">{{ item.percent_score ? `${item?.percent_score }%` : 'Chưa có' }}</td>
          <td v-for="kra in item?.kra_manager_weeks" :key="kra.id">
            <el-select
                v-model="kra.percent_results"
                style="width: 100%;"
                :class="setColorValue(kra.percent_results)"
                @change="handleMarkResultKpi(item)"
                size="small" :disabled="!isEdit"
                clearable placeholder="Chọn">
              <el-option
                  v-for="item in pointOption"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                  :class="setColor(item.value)"
              >
              </el-option>
            </el-select>
          </td>
          <td style="text-align: start">{{ (item?.percent_results || item?.percent_results === 0)  ? `${item?.percent_results}%` : 'Chưa có' }}</td>
          <td id="comment">
            <el-input v-model="item.description" size="small" :disabled="!isEdit" placeholder="Nhập nhận xét..."/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {pointOptionKpi} from "@/common/define";

export default {
  name: 'TableResultKpi',
  data() {
    return {
      pointOption: pointOptionKpi,
      weekNum: null,
      listWeekly: [],
    }
  },
  props: {
    listManagementKpi: {
      type: Array,
      default: [],
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
    setColor(value) {
      switch (value) {
        case 0:
          return 'text-red'
        case 30:
        case 60:
        case 90:
          return 'text-blue'
        case 100:
          return 'text-green'
      }
    },
    setColorValue(value) {
      switch (value) {
        case 0:
          return 'value-color-red'
        case 30:
        case 60:
        case 90:
          return 'value-color-blue'
        case 100:
          return 'value-color-green'
      }
    },
    roundDecimal(value, decimals) {
      if (value % 1 !== 0) {
        return parseFloat(value.toFixed(decimals));
      }
      return value;
    },
    handleMarkResultKpi(kra) {
      let totalPercent = 0
      let lengthMarked = 0
      let totalPercentUser = 0
      kra?.kra_manager_weeks?.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercent += item?.percent_results
          lengthMarked += 1
        }
      })
      kra.percent_results = this.roundDecimal((totalPercent / lengthMarked), 2)
      this.listManagementKpi.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercentUser += (item?.percent_results * item?.percent_score) / 100
        }
      })
      this.$emit('totalPercentKra', this.roundDecimal(totalPercentUser, 2))
    },
  },
}
</script>

<style lang="css">
  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .value-color-red .el-input__inner{
    color: red !important;
  }
  .value-color-blue .el-input__inner{
    color: blue !important;
  }
  .value-color-green .el-input__inner{
    color: green !important;
  }
  .text-red{
    color: red;
  }
  .text-blue{
    color: blue;
  }
  .text-green{
    color: green;
  }
  .headerTable {
    background: #F8F8F8;
    text-align: start;
    word-break: break-word;
  }
  .table-result {
    overflow: auto;
    margin-bottom: 30px;
    max-height: 230px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>