<template>
  <div style="background: white">
    <HeaderListManagementList/>
    <hr style="padding: 0; margin: 0"/>
    <div style="padding: 10px 20px">
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 35px; align-items: end; margin: 10px 0">
        <div>
          <span style="font-weight: 600">Họ tên, mã nhân sự</span>
          <el-input placeholder="Nhập họ tên, mã nhân sự" style="width: 100%;" v-model="query.personnel"></el-input>
        </div>
        <div>
          <span style="font-weight: 600">Đơn vị</span>
          <el-select v-model="query.department" style="width: 100%;" clearable filterable placeholder="Chọn phòng ban">
            <el-option
                v-for="item in departmentOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="font-weight: 600">Trạng thái</span>
          <el-select v-model="query.status" style="width: 100%;" clearable placeholder="Chọn trạng thái">
            <el-option
                v-for="item in statusOption"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="font-weight: 600">Thời gian</span>
          <el-date-picker
              style="width: 100%;"
              v-model="query.time"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              placeholder="Chọn thời gian">
          </el-date-picker>
        </div>
        <el-button type="primary" style="color: white; width: 120px; height: 40px" @click="querySearch">Tìm kiếm</el-button>
      </div>
      <div class="table-responsive mt-8" style="overflow: hidden; padding-bottom: 30px">
        <table class="table" style="text-align: center;">
          <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="w-180px" style="background: #F8F8F8; text-align: start">Tên nhân sự</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">Mã nhân sự</th>
            <th scope="col" class="min-w-160px" style="background: #F8F8F8; text-align: start">Đơn vị</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">% KPI tổng</th>
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start;">Điểm họp tuần</th>
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Người chấm</th>
            <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Trạng thái</th>
            <th scope="col" class="w-120px" style="background: #F8F8F8; text-align: start">Thời gian</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center">
              <i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="listManagementKpi?.length > 0">
          <tr v-for="item of listManagementKpi" :key="item?.index"
              style="border: 1px solid #EBEDF3; border-top: 0">
            <td style="text-align: start" :title="item?.name">{{ item?.name }}</td>
            <td style="text-align: start; text-transform: uppercase">{{ item?.userId + 100000}}</td>
            <td style="text-align: start" :title="item?.department">{{ item?.department }}</td>
            <td><p class="line-clamp-1" style="white-space: pre-wrap; text-align: start">
              {{ item.total ? `${item.total}%` : 'Chưa có' }}</p></td>
            <td style="display: flex; justify-content: start; border-top: 0 !important;">
              {{ item?.point ?? 'Chưa có' }}
            </td>
            <td style="text-align: start" :title="item?.personHandle">{{ item?.personHandle }}</td>
            <td>
              <div style="display: flex; align-items: center; gap: 6px">
                <div :class="setBackgroundLabel(item.status)" style="min-width: 13px ;width: 13px; height: 13px; border-radius: 9999px"></div>
                <p class="line-clamp-1" style="text-align: start; margin-bottom: 0;" :title="item?.statusType">{{ item?.statusType }}</p>
              </div>
            </td>
            <td style="text-align: start">{{ item?.date }}</td>
            <td>
              <button class="btn btn-icon btn-xs btn-outline-success ml-2" @click="openDetailResultPersonnel(item)" title="Xem">
                <i class="far fa-eye"></i>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
          </tbody>
        </table>
        <div v-if="listManagementKpi?.length > 0">
          <div class="edu-paginate mx-auto text-center mt-10 mb-5"
               style="display: flex; justify-content: center; align-items: center; height: 50px">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
            Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="titleDetail"
        :visible.sync="isResultKpi"
        width="96%"
        top="30px"
        :before-close="handleClose"
        :show-close="false"
        v-if="isResultKpi"
    >
      <ResultKpiPersonal @closeDialog="handleClose" :record-kpi-by-user="recordKpiByUser" @getListManagementKpi="getListManagementKpi"/>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {statusManagementKpi} from "@/common/define";
import HeaderListManagementList from "@/view/pages/backoffice/managements/component/HeaderListManagementList.vue";
import {GET_LIST_MANAGEMENT_KPI} from "@/core/services/store/backoffice/mangementKpi";
import {ManagementKpiModel} from "@/view/pages/backoffice/managements/model/ManagementKpiModel";
import ResultKpiPersonal from "@/view/pages/backoffice/managements/component/ResultKpiPersonal.vue";
import {GET_DEPARTMENTS} from "@/core/services/store/ticket/ticket.module";

export default {
  name: 'ListManagementKpi',
  components: {ResultKpiPersonal, HeaderListManagementList},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý", icon: 'far fa-info-square'},
      {title: "Quản lý KPI nhân sự", icon: 'fas fa-list'}
    ]);
    this.getListManagementKpi();
    this.getListDepartment();
  },
  data() {
    return {
      query: {
        personnel: null,
        department: null,
        status: null,
        time: null
      },
      loading: true,
      departmentOptions: [],
      statusOption: statusManagementKpi,
      listManagementKpi: [],
      page: 1,
      last_page: 1,
      total: 0,
      isResultKpi: false,
      titleDetail: '',
      recordKpiByUser: null,
    }
  },
  methods: {
    clickCallback(obj) {
      this.page = obj;
      this.getListManagementKpi()
    },
    handleClose() {
      this.isResultKpi = false
    },
    callApi() {
      const payload = {
        keyword: this.query.personnel,
        department_id: this.query.department ? +this.query.department : null,
        status: this.query.status ? +this.query.status : null,
        month: this.query.time,
        page: this.page,
      }
      this.$store.dispatch(GET_LIST_MANAGEMENT_KPI, payload).then(res => {
        const data = res?.data?.data
        if (!res.data.error) {
          this.total = res.data.total
          this.last_page = res.data.last_page;
          this.listManagementKpi = data.map(item => {
            return new ManagementKpiModel(
                item?.id,
                item?.user?.name,
                item?.user_id,
                item?.department?.name,
                item?.department?.id,
                item?.total_point_kra,
                item?.total_point_meeting,
                item?.assessor?.name,
                item?.status,
                item?.date
            );
          }) ?? []
        }
      }).catch(e => {
        console.log(e, 'import kra error')
      }).finally(() => {
        this.loading = false
      })
    },
    querySearch() {
      this.filterKPI()
      this.callApi()
    },
    getListManagementKpi() {
      this.getParams();
      this.callApi()
    },
    getListDepartment() {
      this.$store.dispatch(GET_DEPARTMENTS).then(res => {
        if (!res.error) {
          this.departmentOptions = res.data ?? []
        }
      })
    },
    openDetailResultPersonnel(record) {
      this.recordKpiByUser = record
      this.titleDetail = `Kết quả KPI nhân sự: ${record.name}`
      this.isResultKpi = true
    },
    setBackgroundLabel(status) {
      switch (status) {
        case 0:
          return 'bg-orange';
        case 1:
          return 'bg-red';
        case 2:
          return 'bg-green';
        default:
          return 'bg-red';
      }
    },
    getParams() {
      this.query.personnel = this.$route.query.keyword
      this.query.department = this.$route.query.department_id ? parseInt(this.$route.query.department_id) : null
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : null
      this.query.time = this.$route.query.month
    },
    filterKPI() {
      this.$router.push({
        name: 'ListManagementKpi',
        query: {
          "keyword": this.query.personnel ?? null,
          "department_id": this.query.department ?? null,
          "status": this.query.status ?? null,
          "month": this.query.time ?? null,
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .bg-orange {
    background: #FF7A00;
  }
  .bg-green {
    background: #00800D;
  }
  .bg-red {
    background: #FF0000;
  }
</style>